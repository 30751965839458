import { gql } from "apollo-boost";

export const userMixin = {
  methods: {
    getUsers(options) {
      const getUserList = gql`
        query userListForAdmin($options: ListUserInput!){
            userListForAdmin(options: $options){
                data{
                    _id
                    firstName
                    lastName
                    email
                    username
                    profileImage
                    phoneNumber
                    phoneNumberCountryCode
                    accountStatus
                    createdAt
                }
                pagination{
                  total
                  totalPage
                  page
                }
            }
          }
        `;
      return this.$apollo.query({
        query: getUserList,
        variables: {
          options
        },
        fetchPolicy: 'network-only'
      }).then(response => {
        return response.data.userListForAdmin
      });
    },
    getUser(userId) {
      const getUserDetail = gql`
        query userDetailForAdmin($userId: ID!){
          userDetailForAdmin(userId: $userId){
                    _id
                    username
                    firstName
                    lastName
                    email
                    aboutMe
                    phoneNumber
                    phoneNumberCountryCode
                    phoneNumberCountry
                    phoneVerification{
                      verificationCodeSentTo
                      verificationCodeSentToCountryCode
                      verificationCodeSentToCountry
                      isVerified
                    }
                    profileImage
                    accountStatus
                    signUpType
                    followersCount
                    followingsCount
            }
          }
        `;
      return this.$apollo.query({
        query: getUserDetail,
        variables: {
          userId
        }
      }).then(response => {
        return response.data.userDetailForAdmin
      });
    },
    addUser(userDetail) {
      let addUser = gql`
        mutation addUserByAdmin($userDetail: UserDetail!){
          addUserByAdmin(userDetail: $userDetail){
               message{
                message
                code
                }
            }
          }
        `;
      return this.$apollo.mutate({
        mutation: addUser,
        variables: {
          userDetail:userDetail
        }
      }).then(response => {
        return response.data.addUserByAdmin
      }).catch(err=>{
        return err;
      });
    },
    removeUser(userId){
      let removeUser = gql`
        mutation removeUserByAdmin($userId: String!){
          removeUserByAdmin(userId: $userId){
              message{
                status
                message
              }
            }
          }
        `;
      return this.$apollo.mutate({
        mutation: removeUser,
        variables: {
          userId
        }
      }).then(response => {
        return response.data.removeUserByAdmin
      }).catch(err=>{
        return err;
      });
    },
    saveUser(userDetail) {

      let user = {
        _id:userDetail._id,
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
        email:userDetail.email,
        username: userDetail.username,
        phoneNumber: userDetail.phoneNumber,
        phoneNumberCountryCode: userDetail.phoneNumberCountryCode,
        phoneNumberCountry: userDetail.phoneNumberCountry,
        accountStatus: userDetail.accountStatus,
        aboutMe: userDetail.aboutMe
      }
      let saveUser = gql`
        mutation saveUserByAdmin($userDetail: EditUserDetail!){
          saveUserByAdmin(userDetail: $userDetail){
                userDetail{
                  _id
                  profileImage,
                  firstName
                  lastName
                  email
                  username
                  phoneNumber
                  phoneNumberCountryCode
                  phoneNumberCountry
                  phoneVerification{
                    verificationCodeSentTo
                    verificationCodeSentToCountryCode
                    verificationCodeSentToCountry
                    isVerified
                  }
                  accountStatus
                  aboutMe
              }
              message{
                status
                message
              }
            }
          }
        `;
      return this.$apollo.mutate({
        mutation: saveUser,
        variables: {
          userDetail:user
        }
      }).then(response => {
        return response.data.saveUserByAdmin
      }).catch(err=>{
        console.log("gql err",err);
        return err;
      });
    },
    saveProfileImage(userDetail) {

      let user = {
        _id:userDetail._id,
        profileImage:userDetail.profileImage
      }
      let saveUser = gql`
        mutation saveProfileImageByAdmin($userDetail: ChangeProfileImage!){
          saveProfileImageByAdmin(userDetail: $userDetail){
                userDetail{
                  _id
                  profileImage
              }
              message{
                status
                message
              }
            }
          }
        `;
      return this.$apollo.mutate({
        mutation: saveUser,
        variables: {
          userDetail:user
        }
      }).then(response => {
        return response.data.saveProfileImageByAdmin
      }).catch(err=>{
        return err;
      });
    },
    changePassword(userId){
      let changePassword = gql`
        mutation changePasswordByAdmin($userId: String!){
          changePasswordByAdmin(userId: $userId){
               message{
                status
                message
              }
            }
          }
        `;
      return this.$apollo.mutate({
        mutation: changePassword,
        variables: {
          userId
        }
      }).then(response => {
        return response.data.changePasswordByAdmin
      }).catch(err=>{
        return err;
      });
    }
  }
}
