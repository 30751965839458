
<template>
  <div id="page-user-view">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All Users</router-link
        >
      </span>
    </vs-alert>

    <div id="user-data" v-if="userDetail">
      <vx-card title="Account" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img :src="(userDetail.profileImage!=='')?userDetail.profileImage:defaultAvatar"
               class="rounded w-full" />
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Username</td>
                <td>{{ userDetail.username }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ userDetail.firstName + " " + userDetail.lastName }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ userDetail.email }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ userDetail.accountStatus }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Contact Number</td>
                <td>{{ userDetail.phoneNumber }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button
              icon-pack="feather"
              icon="icon-edit"
              class="mr-4"
              @click="editDetailHandler($route.params.id)"
              >Edit</vs-button
            >
            <!--<vs-button
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              >Delete</vs-button
            >-->
          </div>
        </div>
      </vx-card>
      <vx-card  title="Information" class="mb-base">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
            <table>
              <tr>
                <td class="font-semibold">Total Followers</td>
                <td>{{ userDetail.followersCount }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Total Followings</td>
                <td>{{ userDetail.followingsCount }}</td>
              </tr>
            </table>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
            <table>
              <tr>
                <td class="font-semibold">About Me</td>
                <td>{{ userDetail.aboutMe }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Sign Up Type</td>
                <td>{{ userDetail.signUpType }}</td>
              </tr>
            </table>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import { userMixin } from "../../mixins/userMixin";

export default {
  name: "UserDetails",
  mixins: [userMixin],
  data: () => ({
    userDetail: null,
    user_not_found: false,
    defaultAvatar:'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
  }),
  methods: {
    ...mapActions("user", ["fetchUser"]),
    getUserDetail(id) {
      let _vue = this;
      this.getUser(id).then((res) => {
        _vue.userDetail = res;
        console.log("user detail",_vue.userDetail);
      });
    },
    editDetailHandler(id) {
      this.$router.push({ name: "EditUserDetail", params: { id: id } });
    }
  },
  created() {
    let userId = this.$route.params.id;
    this.getUserDetail(userId);
  },
};
</script>
<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }
}
</style>
